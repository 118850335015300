import React from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { postPatientRecord } from '../../../actions/assessmentInfo';
import { createLoadingSelector } from '../../../reducers/api';
import Layout from '../../../components/v2/Layout';
import VitalSignInfo from '../../../components/Assessment/VitalSignInfo';

export default () => {
  const dispatch = useDispatch();
  const loading = useSelector(createLoadingSelector(['assessment/patient/post', 'assessment/evaluation/post']));

  const nextStep = async (value) => {
    // try {
    //   await dispatch(postPatientRecord(value));
    //   await dispatch(postEvaluation());
    //   navigate(`/assessment/symptomEntry`);
    // } catch (e) {
    // }
  }

  const consult = async (value) => {
    try {
      await dispatch(postPatientRecord(value));
      navigate('/otp2?noSkip=1&returnUri=/membership/consult/confirmation');
    } catch (e) {
    }
  }

  return (
    <Layout bg="bg2" title="ข้อมูลสัญญาณชีพ (หากมี)">
      <VitalSignInfo title={false} loading={loading} onSubmit={nextStep} onSkip={consult} skipAi/>
    </Layout>
  );
}
